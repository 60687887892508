// General Colors
$white: #fffdff;
$black: #080808;
$purple: #b539d8;
$warning-red: #dd0000;

// Backgrounds
$c-background-primary: $white;
$c-background-purple: $purple;

// Borders
$c-border-primary: $black;
$c-border-secondary: $white;
$c-border-purple: $purple;
$c-border-warning: $warning-red;
$c-border-dark: rgba(0, 0, 0, 0.2);
$c-border-light: #dee2e6;

// Fonts
$c-font-primary: $black;
$c-font-secondary: $white;
$c-font-purple: $purple;

:export {
    white: $white;
    black: $black;
    purple: $purple;
    warningred: $warning-red;

    cbackgroundprimary: $c-background-primary;
    cbackgroundpurple: $c-background-purple;

    cborderprimary: $c-border-primary;
    cbordersecondary: $c-border-secondary;
    cborderpurple: $c-border-purple;
    cborderwarning: $c-border-warning;
    cborderdark: $c-border-dark;
    cborderlight: $c-border-light;

    cfontprimary: $c-font-primary;
    cfontsecondary: $c-font-secondary;
    cfontpurple: $c-font-purple;
}
