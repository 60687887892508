*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

button {
    background-color: transparent;
    color: inherit;
    border-width: 0;
    padding: 0;
    cursor: pointer;
}

figure {
    margin: 0;
}

input::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin: 0;
}

ul,
ol,
dd {
    margin: 0;
    padding: 0;
    list-style: none;
}

p {
    margin: 0;
}

cite {
    font-style: normal;
}

fieldset {
    border-width: 0;
    padding: 0;
    margin: 0;
}
