@import "../../scss/variables";

#contact-form {
    width: 55%;
    min-width: 330px;
    margin: 20px auto;
    background-color: $c-background-primary;
    @include border-card;
    @include border-shadow-soft;
}

#contact-title {
    @extend %card-header;
    font-size: 2.2em;
    margin: 0;
    padding: 6px 0;
    text-align: center;
}

.contact-body {
    @extend %card-body;
}

.contact-row {
    margin: 30px 0;

    label {
        margin-left: 5%;
    }

    input[type],
    textarea,
    select {
        @extend %styled-inputs;
    }

    textarea {
        resize: vertical;
        font-size: 1.25em;
        @include f-body;
    }
}

.contact-submit-row {
    @extend %card-footer;
    padding: 10px;
    text-align: center;
}

.contact-submit-button {
    @include button-purple;
}
